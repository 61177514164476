import { HttpInterceptorsConfig } from "./httpInterceptors";

export class OrdensProducaoService extends HttpInterceptorsConfig {
	async findAllOrdensProducao () {
		const response = await this.axios.get("ordensProducao/findAllOrdensProducao");
		return response.data;
	}

	async findOrdensProducaoByParams (filters) {
		const response = await this.axios.get("ordensProducao/findOrdensProducaoByParams", { params: { filters }});
		return response.data;
	}

	async upsertOrdemProducao (payload) {
		const response = await this.axios.post("ordensProducao/upsertOrdemProducao", payload);
		return response.data;
	}

	async deleteOrdemProducao (id) {
		const response = await this.axios.delete("ordensProducao/deleteOrdemProducao", { params: { id }});
		return response.data;
	}

	async changeStatusOrdemProducao (payload) {
		const response = await this.axios.patch("ordensProducao/changeStatusOrdemProducao", payload);
		return response.data;
	}
}
