<template>
	<div>
		<Template-base-empty class="containerCard">
			<div class="container-row-main" :style="'width: calc(100% + 30px) !important;'">
				<div class="container-head">
					<span class="container-head-title">Pesquisar</span>
					<div v-if="possuiPermissao('GER_I_ORDENS_PRODUCAO')" class="container-add-new">
						<b-button class="add-button" @click="mostrarEdicao()">
							Adicionar
						</b-button>
					</div>
				</div>
				<div class="head-row">
					<b-col cols="6" class="text-head-row">
						<label>
							Injetora
						</label>
						<vue-multi-select
							class="multi-100 mb-1 style-select"
							:disabled="loading"
							v-model="selectedOptionMaquina"
							search
							searchPlaceholder="Pesquisar"
							:options="{ multi: true, labelName: 'nome' }"
							:selectOptions="selectMaquinas"
							data-cy="injetora"
							:btnLabel="() => (selectedOptionMaquina.length > 1 ? `${selectedOptionMaquina[0].nome}...` : selectedOptionMaquina[0] && selectedOptionMaquina[0].nome) || 'Selecione'"
						/>
					</b-col>
					<b-col cols="6" class="text-head-row">
						<label>
							Status das ordens de Produção
						</label>
						<vue-multi-select
							class="multi-100 mb-1 style-select"
							:disabled="loading"
							v-model="selectedStatusOrdemProducao"
							:options="{ multi: false, labelName: 'nome' }"
							:selectOptions="statusOrdensProducao"
							data-cy="ordens_de_producao"
							:btnLabel="() => (selectedStatusOrdemProducao[0] && selectedStatusOrdemProducao[0].nome) || 'Selecione'"
						/>
					</b-col>
				</div>
				<div class="head-row">
					<b-col cols="12" class="text-head-row">
						<label>
							Nome do Processo
						</label>
						<b-form-input
							class="multi-100 mb-5"
							:disabled="loading"
							v-model="buscarNomeProcesso"
							placeholder="Pesquisar o nome do processo"
						/>
					</b-col>
				</div>
				<div class="container-pesquisar mt-2">
					<b-button class="btn-pesquisar" @click="findOrdensProducao()">
						<SearchIcon width="104" height="28"></SearchIcon>
					</b-button>
				</div>
			</div>
		</Template-base-empty>
		<template-base
			v-if="!semPesquisa"
			class="container-card mt-3"
			style="margin-top: 0">
			<div
				v-if="ordensProducaoWithMaquina.length"
				class="container-table"
				:style="'width: calc(100% + 30px) !important;'">

				<div class="container-pages ml-1">
					<b-pagination
						id="page"
						v-model="currentPage"
						:total-rows="pagination.count"
						:per-page="pagination.limit.value"
					></b-pagination>
				</div>
				<div class="head-row-pagination">
					<span class="text-head-row-pagination ml-2">
						Resultados {{ pagination.offset + 1 }}-{{ pagination.offset + ordensProducaoWithMaquina.length }} de {{ pagination.count }}
					</span>
					<b-form
						class="text-head-row-pagination">
						<label class="label-pagination mr-2">
							Itens por página:
						</label>
						<b-form-select
							class="mr-1"
							v-model="pagination.limit.value"
							name="itens-por-pagina"
							:options="optionsItensPorPag"
							@input="changeItensByPage()"
							:disabled="loading"
						/>
					</b-form>
				</div>

				<table class="mt-2" data-cy="Tabela" :data-asc="true">
					<tbody v-if="loading">
						<tr v-for="i in 3" :key="i">
							<td class="p-1" v-for="j in colunas.length + 1" :key="j">
								<tb-skeleton shape="rect" style="background-color: #dcdcdc; height: 2rem; width:100%" theme="opacity"></tb-skeleton>
							</td>
						</tr>
					</tbody>
					<tbody v-else>
						<tr v-for="(item, idx) in ordensProducaoWithMaquina" :key="idx">
							<td class="table-rows">
								<div class="card-table">
									<div class="card-left-table">
										<div class="card-left-head">
											<div style="display: block; width: Calc(100% - 64px);">
												<div class="card-left-title">
													ORDEM DE PRODUÇÃO
												</div>
												<div
													class="card-left-injetora"
													:title="item.maquina.nome"
												>
													{{ item.maquina.nome }}
												</div>
											</div>
											<div class="card-left-icon">
												<CalendarIcon class="calendar-icon"/>
											</div>
										</div>
										<div
											class="card-left-bottom"
											v-bind:class="item.ativa ? 'card-background-ativa' : ''"
											:title="item.nomeProcesso"
										>
											<span>
												{{ item.nomeProcesso.length > 110 ? `${item.nomeProcesso.substring(0, 110)}...` : item.nomeProcesso }}
											</span>
										</div>
									</div>
									<div
										class="card-center-table"
										v-bind:class="item.ativa ? 'card-border-ativa' : ''"
									>
										<div
											class="card-center-div"
											v-bind:class="item.ativa ? 'card-center-ativa' : ''"
										>
											<div class="card-center-info">
												<ActivityIcon/>
												<div class="card-center-title">
													<div>
														TEMPO DE CICLO
													</div>
													<div>
														TEÓRICO
													</div>
													<div
														class="card-center-value"
														v-bind:class="item.ativa ? 'card-center-value-ativa' : ''"
													>
														{{ getSecondsFormatted(item.tempoCicloTeorico) }} seg
													</div>
												</div>
											</div>
											<div class="card-center-info">
												<BoxIcon/>
												<div class="card-center-title">
													<div>
														PEÇAS POR
													</div>
													<div>
														BATIDAS
													</div>
													<div
														class="card-center-value"
														v-bind:class="item.ativa ? 'card-center-value-ativa' : ''"
													>
														{{ getNumberFormatted(item.pecasPorBatida) }} Peças
													</div>
												</div>
											</div>
											<div class="card-center-info">
												<PackageIcon/>
												<div class="card-center-title">
													<div>
														PEÇAS TOTAL
													</div>
													<div>
														ESPERADA
													</div>
													<div
														class="card-center-value"
														v-bind:class="item.ativa ? 'card-center-value-ativa' : ''"
													>
														{{ getNumberFormatted((item.batidas * item.pecasPorBatida)) }}
													</div>
												</div>
											</div>
											<div class="card-center-info">
												<ActivityIcon/>
												<div class="card-center-title">
													<div>
														TEMPO DE DOSAGEM
													</div>
													<div>
														TEÓRICO
													</div>
													<div
														class="card-center-value"
														v-bind:class="item.ativa ? 'card-center-value-ativa' : ''"
													>
														{{ getSecondsFormatted(item.tempoDosagemTeorico) }} seg
													</div>
												</div>
											</div>
											<div class="card-center-info">
												<SunsetIcon/>
												<div class="card-center-title">
													<div>
														BATIDAS TOTAIS
													</div>
													<div>
														A SEREM EXECUTADAS
													</div>
													<div
														class="card-center-value"
														v-bind:class="item.ativa ? 'card-center-value-ativa' : ''"
													>
														{{ getNumberFormatted(item.batidas) }} Batidas
													</div>
												</div>
											</div>
											<div class="card-center-info">
												<ClockIcon/>
												<div class="card-center-title">
													<div>
														TEMPO ESTIMADO
													</div>
													<div>
														DE FINALIZAÇÃO
													</div>
													<div
														class="card-center-value"
														v-bind:class="item.ativa ? 'card-center-value-ativa' : ''"
													>
														{{ getTimeFormatted(item.tempoEstimadoFinalizacao) }}
													</div>
												</div>
											</div>
										</div>
										<div class="container-acoes">
											<td>
												<div
													v-if="possuiPermissao('GER_U_ORDENS_PRODUCAO')"
													class="icon-acao icon-edit-bg icon-container"
													@click="mostrarEdicao(item)"
													title="Editar"
												>
													<Edit3Icon size="24" alt="Edita"/>
												</div>
												<div
													v-if="possuiPermissao('GER_D_ORDENS_PRODUCAO')"
													class="icon-acao icon-delete-bg icon-container"
													@click="confirmaApagar(item)"
													title="Apagar"
												>
													<Trash2Icon size="24" alt="Apagar"/>
												</div>
												<div class="content-info">
													<div :title="info_ordem_producao" class="text-info">
														<span>?</span>
													</div>
												</div>
											</td>
										</div>
									</div>
									<div
										class="card-right-table"
										v-bind:class="item.ativa ? 'card-background-ativa' : ''"
										@click="confirmaMudarStatusOrdemProducao(item, item.ativa ? 'desativar' : 'ativar', false)"
									>
										<span v-if="item.ativa">
											ATIVA
										</span>
										<span v-else>
											NÃO ATIVA
										</span>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-else class="alert alert-warning" role="alert">
				{{ errMsg }}
			</div>
		</template-base>
		<b-modal
			ref="modalEdicao"
			:title="titleModalEdicao"
			size="lg"
			dialog-class="modal-dialog-centered"
		>
			<div class="d-block mb-3">
				<b-row class="mb-3 ml-0 mr-0">
					<label class="modal-edicao-label">Injetora</label>
					<b-form-select
						class="style-select-modal"
						name="injetoraModal"
						v-model="ordemProducaoSelecionada.id_maquina"
						:options="selectMaquinaModal"
						:disabled="loading"
						v-validate="{ required: true }"
						:state="validateState('injetoraModal')"
					>
						<template slot="first">
							<option :disabled="false" :value=null>
								Nenhuma Selecionada
							</option>
						</template>
					</b-form-select>
				</b-row>
				<b-row class="mb-3 ml-0 mr-0">
					<label class="modal-edicao-label">Processo</label>
					<b-form-input
						class="modal-edicao-input"
						name="processo"
						v-model="ordemProducaoSelecionada.nome_processo"
						autocomplete="off"
						v-validate="{ required: true }"
						:state="validateState('processo')"
						placeholder="Digite o nome do processo"
					/>
				</b-row>
				<b-row class="mb-3">
					<b-col cols="4" class="pr-1">
						<label class="modal-edicao-label">Batidas</label>
						<b-form-input
							class="text-center modal-edicao-input custom-number-input"
							name="batidas"
							v-model="ordemProducaoSelecionada.batidas"
							autocomplete="off"
							v-validate="{ required: true, min_value: '1' }"
							:state="validateState('batidas')"
							placeholder="Nº total de batidas"
							type="number"
							min="1"
						/>
					</b-col>
					<b-col cols="4" class="pl-1 pr-1">
						<label class="modal-edicao-label">Peças por Batidas</label>
						<b-form-input
							class="text-center modal-edicao-input custom-number-input"
							name="pecas_por_batida"
							v-model="ordemProducaoSelecionada.pecas_por_batida"
							autocomplete="off"
							v-validate="{ required: true, min_value: '1' }"
							:state="validateState('pecas_por_batida')"
							placeholder="Nº de peças"
							type="number"
							min="1"
						/>
					</b-col>
					<b-col cols="4" class="pl-1">
						<label class="modal-edicao-label">Gramas por Batida</label>
						<b-form-input
							class="text-center modal-edicao-input custom-number-input"
							name="gramas_por_batida"
							v-model="ordemProducaoSelecionada.gramas_por_batida"
							autocomplete="off"
							v-validate="{ required: true, min_value: '1' }"
							:state="validateState('gramas_por_batida')"
							placeholder="Gramas de material gasto por batida"
							type="number"
							min="1"
						/>
					</b-col>
				</b-row>
				<b-row class="mb-3">
					<b-col cols="6" class="pr-1">
						<label class="modal-edicao-label">Tempo Teórico de Ciclo</label>
						<b-form-input
							class="text-center modal-edicao-input custom-number-input"
							name="tempo_ciclo_teorico"
							v-model="ordemProducaoSelecionada.tempo_ciclo_teorico"
							autocomplete="off"
							v-validate="{ required: true, min_value: '0.001' }"
							:state="validateState('tempo_ciclo_teorico')"
							placeholder="0,000 seg"
							type="number"
							min="0.001"
							step="0.001"
						/>
					</b-col>
					<b-col cols="6" class="pl-1">
						<label class="modal-edicao-label">Tempo Teórico de Dosagem</label>
						<b-form-input
							class="text-center modal-edicao-input custom-number-input"
							name="tempo_dosagem_teorico"
							v-model="ordemProducaoSelecionada.tempo_dosagem_teorico"
							autocomplete="off"
							v-validate="{ required: true, min_value: '0.001' }"
							:state="validateState('tempo_dosagem_teorico')"
							placeholder="0,000 seg"
							type="number"
							min="0.001"
							step="0.001"
						/>
					</b-col>
				</b-row>
			</div>
			<template #modal-footer>
				<div class="w-100 d-flex justify-content-between">
					<div class="btn-group">
						<b-button class="btn-modal btn-cancel" @click="fecharModal('modalEdicao')">Cancelar</b-button>
						<b-button class="btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
					</div>
					<b-button
						class="btn-modal btn-salvar"
						@click="confirmaMudarStatusOrdemProducao(ordemProducaoSelecionada, ordemProducaoSelecionada.ativa ? 'desativar' : 'ativar', true)"
						:disabled="invalidForm"
					>
						Salvar
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
	import VueMultiSelect from "vue-multi-select";
	import "vue-multi-select/dist/lib/vue-multi-select.css";

	import { formatTime, numberFormatted } from "@/helpers/common";
	import TemplateBaseEmpty from "@/templates/BaseEmpty";
	import TemplateBase from "@/templates/Base.vue";

	import { MaquinasService } from "../../services/maquinas";
	import { possuiPermissao } from "../../helpers/permissions";
	import { OrdensProducaoService } from "../../services/ordensProducao";
	import { hasFieldsWithErrors } from "@/helpers/validators";

	export default {
		components: {
			TemplateBase,
			TemplateBaseEmpty,
			VueMultiSelect
		},

		inject: ["parentValidator"],

		data () {
			return {
				maquinas: [],
				ordensProducao: [],
				selectMaquinas: [],
				statusOrdensProducao: [
					{
						value: 0,
						nome: "Ativadas e Desativadas"

					},
					{
						value: 1,
						nome: "Ativadas"
					},
					{
						value: 2,
						nome: "Desativadas"
					}
				],
				selectedOptionMaquina: [],
				selectedStatusOrdemProducao: [
					{
						nome: "Ativadas e Desativadas",
						value: 0
					}
				],
				selectMaquinaModal: [],
				ordensProducaoWithMaquina: [],
				ordemProducaoSelecionada: {
					id: null,
					id_maquina: null,
					nome_processo: null,
					batidas: null,
					pecas_por_batida: null,
					tempo_ciclo_teorico: null,
					tempo_dosagem_teorico: null,
					ativa: false,
					gramas_por_batida: null
				},

				titleModalEdicao: "",
				loading: false,
				invalidForm: true,
				maquinasService: new MaquinasService(),
				ordensProducaoService: new OrdensProducaoService(),

				errMsg: "",
				colunas: [
					"Injetora",
					"Ordem"
				],

				possuiPermissao,

				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Itens"
					},
					{
						value: 25,
						text: "25 Itens"
					},
					{
						value: 50,
						text: "50 Itens"
					},
					{
						value: 75,
						text: "75 Itens"
					},
					{
						value: 100,
						text: "100 Itens"
					}
				],
				semPesquisa: true,
				info_ordem_producao: "",
				buscarNomeProcesso: ""
			};
		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		created () {
			this.$validator = this.parentValidator;
		},

		mounted () {
			this.fillMaquinas();

			const line_1 = "Tempo estimado de finalização = Quantidade de Batidas x Tempo de Ciclo.";
			const line_2 = "OBS: O tempo estimado de finalização não computa paradas.";
			this.info_ordem_producao = `${line_1}\n\n${line_2}`;
		},

		methods: {
			changePage () {
				this.pagination.page = this.currentPage;
				this.findOrdensProducao();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findOrdensProducao();
			},

			async fillMaquinas () {
				this.maquinas = await this.maquinasService.listAllMachines() || [];
				this.selectMaquinas = this.maquinas.map(m => ({
					value: m.id,
					nome: m.nome
				}));
				this.selectMaquinaModal = this.selectMaquinas.map(s => ({
					value: s.value,
					text: s.nome
				}));
			},

			async findOrdensProducao () {
				this.loading = true;

				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;

				const statusOrdensProducaoFind = this.selectedStatusOrdemProducao[0]?.value === 1 ? "true"
					: this.selectedStatusOrdemProducao[0]?.value === 2 ? "false"
						: null;

				const filters = {
					id_maquina: this.selectedOptionMaquina.map(m => m.value),
					ativa: statusOrdensProducaoFind,
					nome_processo: `%${this.buscarNomeProcesso}%`,
					pagination: {
						offset: this.pagination.offset,
						limit
					}
				};

				const result = await this.ordensProducaoService.findOrdensProducaoByParams(filters) || {};
				this.ordensProducaoWithMaquina = result.rows || [];
				this.pagination.count = result.count || 0;

				if (!this.ordensProducaoWithMaquina.length)
					this.errMsg = "Nenhuma ordem de produção encontrada.";

				this.semPesquisa = false;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			mostrarEdicao (item) {
				if (!item?.id)
					this.titleModalEdicao = "Cadastrar Ordem de Produção";
				else
					this.titleModalEdicao = "Editar Ordem de Produção";

				this.invalidForm = true;
				this.ordemProducaoSelecionada.id = item?.id;
				this.ordemProducaoSelecionada.id_maquina = item?.idMaquina || null;
				this.ordemProducaoSelecionada.nome_processo = item?.nomeProcesso || "";
				this.ordemProducaoSelecionada.batidas = item?.batidas;
				this.ordemProducaoSelecionada.pecas_por_batida = item?.pecasPorBatida;
				this.ordemProducaoSelecionada.tempo_ciclo_teorico = item ? (item.tempoCicloTeorico / 1000).toFixed(3) : null;
				this.ordemProducaoSelecionada.tempo_dosagem_teorico = item ? (item.tempoDosagemTeorico / 1000).toFixed(3) : null;
				this.ordemProducaoSelecionada.ativa = item?.ativa || false;
				this.ordemProducaoSelecionada.gramas_por_batida = item?.gramasPorBatida;
				this.$refs.modalEdicao.show();
			},

			async confirmaApagar (item) {
				const deleteConfirmation = (await this.$swal({
					title: `Excluir a Ordem de Produção \n "${item.nomeProcesso}"`,
					text: "Tem certeza que deseja remover a ordem de produção?",
					type: "warning",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.id);

			},

			async salvar (activateOrdemProducao) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				let ativa;
				if (!this.ordemProducaoSelecionada.id)
					ativa = Boolean(activateOrdemProducao);
				else if (!activateOrdemProducao)
					ativa = this.ordemProducaoSelecionada.ativa;
				else
					ativa = !this.ordemProducaoSelecionada.ativa;

				this.$snotify.async("Aguarde...", "Salvando!", async () => {
					try {
						const payload = {
							idMaquina: this.ordemProducaoSelecionada.id_maquina,
							nomeProcesso: this.ordemProducaoSelecionada.nome_processo,
							batidas: +this.ordemProducaoSelecionada.batidas,
							pecasPorBatida: +this.ordemProducaoSelecionada.pecas_por_batida,
							tempoCicloTeorico: +this.ordemProducaoSelecionada.tempo_ciclo_teorico * 1000,
							tempoDosagemTeorico: +this.ordemProducaoSelecionada.tempo_dosagem_teorico * 1000,
							ativa,
							gramasPorBatida: this.ordemProducaoSelecionada.gramas_por_batida
						};

						// ID será enviado apenas se necessário
						if (Number.isInteger(this.ordemProducaoSelecionada.id)) {
							payload.id = this.ordemProducaoSelecionada.id;
						}

						await this.ordensProducaoService.upsertOrdemProducao(payload);

						this.fecharModal("modalEdicao");
						this.findOrdensProducao();

						return {
							title: "Sucesso!",
							body: "Ordem de produção salva com sucesso.",
							config
						};
					} catch (error) {
						throw {
							title: "Falha!",
							body: "Erro ao tentar salvar a ordem de produção.",
							config
						};
					}
				});
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
			},

			async apagar (id) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando!", async () => {
					try {
						await this.ordensProducaoService.deleteOrdemProducao(id);
						this.findOrdensProducao();
						this.fillMaquinas();

						return {
							title: "Sucesso!",
							body: "Ordem de produção apagada com sucesso.",
							config
						};
					} catch (error) {
						throw {
							title: "Falha!",
							body: "Erro ao tentar apagar a ordem de produção.",
							config
						};
					}
				});
			},

			limparModal () {
				this.ordemProducaoSelecionada.nome_processo = "";
				this.ordemProducaoSelecionada.batidas = "";
				this.ordemProducaoSelecionada.pecas_por_batida = "";
				this.ordemProducaoSelecionada.tempo_ciclo_teorico = "";
				this.ordemProducaoSelecionada.tempo_dosagem_teorico = "";
				this.ordemProducaoSelecionada.id_maquina = null;
				this.ordemProducaoSelecionada.gramas_por_batida = "";
			},

			async confirmaMudarStatusOrdemProducao (item, action, updateAll) {
				if (!possuiPermissao("GER_U_ORDENS_PRODUCAO") && !possuiPermissao("GER_I_ORDENS_PRODUCAO")) return;

				const text = action === "ativar" ? `Deseja ${action} a ordem de produção sobrepondo a anterior, caso exista?`
					: `Deseja ${action} a ordem de produção?`;

				let activateOrdemProducao = false;
				if (action === "ativar" || !updateAll) {
					activateOrdemProducao = (await this.$swal({
						title: "Confirmação",
						text,
						type: "warning",
						showCancelButton: true,
						cancelButtonColor: "#A6A8AB",
						confirmButtonColor: "#DD6B55",
						cancelButtonText: "Não",
						confirmButtonText: "Sim",
						reverseButtons: true
					})).value;
				}

				if (updateAll)
					this.salvar(activateOrdemProducao);
				else if (activateOrdemProducao)
					this.mudarStatusOrdemProducao(item, action);

			},

			async mudarStatusOrdemProducao (item, action) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};
				const { id, idMaquina, ativa} = item;
				const payload = {
					id,
					idMaquina,
					ativa: !ativa
				};

				const workNotify = action === "ativar" ? "Ativando!" : "Desativando!";
				this.$snotify.async("Aguarde...", workNotify, async () => {
					try {
						await this.ordensProducaoService.changeStatusOrdemProducao(payload);
						const newStatusOrdemProducao = this.ativa ? "desativada" : "ativada";
						this.findOrdensProducao();

						return {
							title: "Sucesso!",
							body: `Ordem de produção ${newStatusOrdemProducao} com sucesso.`,
							config
						};
					} catch (error) {
						throw {
							title: "Falha!",
							body: "Erro ao tentar editar a ordem de produção.",
							config
						};
					}
				});

			},

			getTimeFormatted (value) {
				return formatTime(Math.round(value));
			},

			getSecondsFormatted (value) {
				return parseFloat(value / 1000).toFixed(3).replace(".", ",");
			},

			getNumberFormatted (value) {
				return numberFormatted(value);
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			}
		}
	};
</script>

<style scoped>
	.label-pagination {
		width: 160px;
	}

	.card-table {
		display: flex;
		background-color: #FFFFFF;
		border-radius: 10px;
		width: 100%;
		height: 168px;
		min-width: 800px;
		cursor: default;
	}

	.card-left-table {
		border-top: 10px solid #20B2AA;
		border-radius: 10px 0;
		width: 35%;
		height: 100%;
		font-weight: 700;
	}

	.card-left-head {
		display: flex;
		height: 58%;
		padding: 8px;
	}

	.card-left-title {
		font-size: 20px;
		color: #20B2AA;
		margin-left: 8px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.card-left-injetora {
		display: inline-block;
		font-size: 18px;
		color: #FFFFFF;
		background-color: #20B2AA;
		max-width: 95%;
		min-width: 50%;
		height: 32px;
		margin-left: 8px;
		border-radius: 5px;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 2px 10px;
	}

	.card-left-bottom {
		width: 100%;
		height: 42%;
		display: flex;
		border-radius: 0 0 0 10px;
		background-color: #B2B2B2;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: #FFFFFF;
		font-size: 21px;
		line-height: 21px;
		padding-top: 2px;
		padding-left: 8px;
		padding-right: 8px;
		text-overflow: ellipsis;
	}

	.card-left-icon {
		display: flex;
		width: 65px;
		justify-content: center;
		align-items: center;
	}

	.calendar-icon {
		width:48px;
		height: 48px;
		color:#20B2AA;
	}

	.card-center-table {
		display: flex;
		border: 2px solid #B2B2B2;
		width: 55%;
		font-weight: 400;
		font-size: 12px;
		line-height: 13px;
	}

	.card-border-ativa {
		border-color: #218838;
	}

	.card-background-ativa {
		background-color: #218838 !important;
	}

	.card-center-div {
		display: grid;
		width: calc(100% - 46px);
		height: 100%;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		align-items: center;
		justify-content: center;
		padding: auto;
		color: #B2B2B2;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		align-content: space-between;
	}

	.card-center-ativa {
		color: #20B2AA !important;
	}

	.card-center-value-ativa {
		color: #6C757D !important;
	}

	.card-center-info {
		display: flex;
		margin-left: 10px
	}

	.card-center-title {
		display: block;
		margin-left: 3px;
	}

	.card-center-value {
		font-size: 23px;
		line-height: 26px;
	}

	.card-right-table {
		display: flex;
		border-radius: 0 10px 10px 0;
		background-color: #B2B2B2;
		width: calc(10% - 3px);
		height: calc(100% - 3px);
		justify-content: center;
		align-items: center;
		text-align: center;
		color: #FFFFFF;
		font-weight: 800;
		font-size: 21px;
		line-height: 21px;
		padding-left: 8px;
		padding-right: 8px;
		box-shadow: 1px 2px 1px 1px rgba(0, 0, 0, 0.6);
		border-style: none;
		cursor: pointer;
	}

	.transform-animation {
		transition: all 0.6s;
	}

	.card-right-table:hover {
		filter: brightness(105%);
	}

	.card-right-table:active {
		filter: brightness(95%);
		width: calc(10% - 2px);
		height: calc(100% - 0px);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
		border-top: 2px;
		border-left: 2px;
		border-bottom: none !important;
		border-right: none !important;
		border-style: outset;
		border-color: rgba(0, 0, 0, 0.6) !important;
	}

	.container-table {
		margin-left: -15px;
		overflow-y: auto;
		padding: 0 12px;
	}

	table {
		width: 100%;
	}

	.table-rows {
		margin-bottom: 10px;
		padding: 0 0 20px 0;
	}

	.container-row-main {
		margin-left: -15px;
		background-color: #FFFFFF;
		border-radius: 10px;
		box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
	}

	.container-head {
		display: flex;
		height: 56px;
		background-color: #6dcff6 ;
		border-radius: 10px 10px 0 0;
		padding-top: 8px;
	}

	.container-pages {
		display: flex;
		width: 70%;
	}

	.container-add-new {
		display: flex;
		width: 50%;
		height: 3rem;
		justify-content: right;
		margin-right: 8px;
	}

	.container-head-title {
		width: 50%;
		height: 3rem;
		margin-left: 16px;
		margin-top: -5px;
		color: #FFFFFF;
		font-size: 30px;
		font-weight: 800;
	}

	.add-button {
		width: 135;
		height: 43;
		font-weight: 700;
		background-color: #218838;
		color: #FFFFFF;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		padding: .75rem 1.5rem;
		border-style: none;
		margin-bottom: .5rem;
	}

	.container-pesquisar {
		display: flex;
		justify-content: right;
		border-style: none;
	}

	.btn-pesquisar {
		border-radius: 10px 0;
		background-color: #218838;
		color: #FFFFFF;
		font-size: 20px;
		border-style: none;
	}

	.container-acoes {
		display: block;
		width: 46px;
		padding: 6px;
	}

	.icon-acao {
		height: 32px;
		width: 32px;
		border-radius: 3px;
		margin-bottom: 5px;
	}

	.icon-delete-bg {
		display: flex;
		justify-content: center;
		background-color: red;
		padding: 2px;
	}

	.icon-edit-bg {
		display: flex;
		justify-content: center;
		background-color: grey;
		padding: 2px;
	}

	.icon-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1px;
		color: white;
		cursor: pointer;
	}

	.content-info {
		margin-top: 4px;
		padding-left: 12px;
	}

	.text-info {
		display: flex;
		height: 18px;
		width: 18px;
		font-size: 16px;
		background-color: #BABABA;
		border-radius: 50%;
		color: white !important;
		align-items: center;
		justify-content: center;
		padding-top: 1px;
		padding-right: 1px;
	}

	.container-card {
		max-width: 90.5%;
	}

	.head-row {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
	}

	.head-row-pagination {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		margin-top: -16px;
	}

	.text-head-row-pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1rem;
		color: #000000;
		white-space: nowrap;
	}

	.text-head-row {
		display: block;
		align-items: center;
		font-weight: 400;
		font-size: 18px;
		margin-top: 16px;
	}

	.modal-edicao-label {
		font-size: 1rem;
		margin-bottom: 1px;
	}

	.modal-edicao-input {
		font-size: 1rem;
		width: 100%;
	}

	.btn-modal {
		font-size: 1rem;
		font-weight: 400;
		color: white;
	}

	.btn-cancel {
		background-color: #343A40;
		border-radius: 4px 0 0 4px;
		border-style: none;
	}

	.btn-limpar {
		background-color: #6C757D;
		border-radius: 0 4px 4px 0;
		border-style: none;

	}

	.btn-salvar {
		background-color: #28A745;
		width: 168px;
		border-radius: 4px;
		border-style: none;
	}

	.style-select {
		color: #28A745;
		border-color: #28A745;
		font-size: 14px;
	}

	.style-select-modal {
		font-size: 1rem;
	}
	.custom-number-input {
		padding-right: 35px !important;
	}
</style>
